@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --color-start: 0 163 255; /* #00a3ff */
  --color-end: 127 49 255; /* #7f31ff */
  --color-placeholder: 236 236 236; /* #f2f2f2 */
}

.text-gradient {
  background-image: linear-gradient(
    309.91deg,
    rgb(var(--color-start)) 16.3%,
    rgb(var(--color-end)) 86.99%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.background-gradient {
  background-image: linear-gradient(
    309.91deg,
    rgb(var(--color-start)) 16.3%,
    rgb(var(--color-end)) 86.99%
  );
}

.index-layout {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

@media (min-width: 1024px) {
  .index-layout {
    display: grid;
    grid-template-columns: minmax(0, 460px) minmax(0, 354px) minmax(0, 356px);
    grid-template-rows: 354px 317px auto 850px;
    grid-template-areas:
      'base-info base-info nft-holding'
      'ability token-holding nft-holding'
      'associated-wallets associated-wallets associated-wallets'
      'recent-activities recent-activities recent-activities';
  }
}

.privacy-page strong {
  font-size: 18px;
}

.privacy-page p {
  margin-block-start: 1em;
  margin-block-end: 1em;
}
